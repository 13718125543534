import React, { useState } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { useGetAllLeadsQuery, useUpdateLeadStatusMutation } from '../../../apis/leadApis/LeadApi';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import LeadsTable from '../../../components/leadsTable/LeadsTable';

const LeadQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, error, refetch } = useGetAllLeadsQuery({
    keyword: searchParams.get('keyword') || '',
  });
//   const [updateLeadStatus] = useUpdateLeadStatusMutation();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'}>
          <Typography variant="h5" fontWeight={700}>
            Leads Query Table
          </Typography>
          <button
            type="submit"
            className="h-full p-1 text-sm font-medium text-white bg-blue-700 border border-blue-700 rounded-e-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Refresh
          </button>
        </Box>
      </Box>
      <Divider role="presentation" sx={{ marginTop: '0.3' }} />
      <br />
      {!isLoading && !isFetching && data?.data.length > 0 && (
        <LeadsTable data={data} />
      )}
    </>
  );
};

export default LeadQuery;
