import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const tabs = [
  'Itineraries',
  'Activities',
  'Bookings',
  'Location',
  'Users',
  'visa',
  'AdminTemplate',
  'Hotels',
  'Leads-Query'
];
const agentTabs = ['Itineraries', 'Bookings'];
const Dashboard = () => {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [tabList, setTabList] = React.useState(agentTabs);
  const { pathname } = useLocation();

  useEffect(() => {
    if (user.userType === 'Admin') {
      setTabList(tabs);
    } else {
      setTabList(agentTabs);
    }
    const tabIndex = tabs.findIndex(
      (x) => `/dashboard/${x.toLowerCase()}` === pathname,
    );
    tabIndex !== -1 && handleChange('', tabIndex);
    if (pathname === '/dashboard') {
      if (user.userType === 'Admin') {
        navigate(`/dashboard/itineraries`);
        setValue(0);
      } else {
        navigate(`/dashboard/itineraries`);
        setValue(0);
      }
    }
  }, [pathname]);

  const handleChange = (e, newValue) => {
    // console.log(newValue);
    newValue >= tabList.length && (newValue = tabList.length - 1);
    setValue((prev) => {
      // console.log('prev...', prev);
      return newValue;
    });
  };
  // console.log(user.userType);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tab of all users"
          scrollButtons="auto"
        >
          {user.userType !== 'Admin'
            ? tabList.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  onClick={(e) => {
                    navigate(
                      `/dashboard/${e.target.textContent.toLowerCase()}`,
                    );
                  }}
                />
              ))
            : tabList.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  onClick={(e) => {
                    navigate(
                      `/dashboard/${e.target.textContent.toLowerCase()}`,
                    );
                  }}
                />
              ))}
        </Tabs>
      </Box>
      <Box sx={{ padding: '0.7rem' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
