import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMeQuery } from '../../apis/authApis/loginApi';

const LABEL_CLASS = 'block mb-2 text-sm font-medium text-gray-900';
const INPUT_CLASS =
  'bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 disabled:bg-gray-50 disabled:border-gray-300';
export default function Profile() {
  const navigate = useNavigate();
  const {
    data: userData,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetMeQuery();

  useEffect(() => {
    refetch();
  }, []);
  // Render based on user data or loading/error state
  if (isLoading) {
    return <p>Loading profile data...</p>;
  }

  if (isError) {
    return <p>Error fetching profile data</p>;
  }

  console.log(userData);

  return (
    <div className="p-2 md:p-4">
      <div className="w-full px-6 pb-8 mt-8 sm:rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5">
            <h2 className="text-4xl font-bold">Your Profile</h2>
           
            <span className="bg-green-800 text-white text-base font-medium px-2.5 py-0.5 rounded-2xl">
              {userData.data[0].verified === true ? 'Verified' : 'Not Verified'}
            </span>
          </div>
          
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => navigate('edit')}
          >
            Edit
          </button>
        </div>
        <p className="mt-3 font-bold">AgencyId - {userData.data[0].agentId}</p>
        <div className="grid mt-8">
         
          <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
            <img
              className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
              src={userData.data[0].company_logo}
              alt="Company Logo"
            />
          </div>

          <div className="items-center mt-8 sm:mt-14 text-[#202142]">
            <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
              <div className="w-full">
                <label htmlFor="first_name" className={LABEL_CLASS}>
                  Your first name
                </label>
                <input
                  value={userData.data[0].first_name}
                  type="text"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>

              <div className="w-full">
                <label htmlFor="last_name" className={LABEL_CLASS}>
                  Your last name
                </label>
                <input
                  type="text"
                  value={userData.data[0].last_name}
                  className={INPUT_CLASS}
                  disabled
                />
              </div>
              <div className="w-full">
                <label htmlFor="company_name" className={LABEL_CLASS}>
                  Company Name
                </label>
                <input
                  type="text"
                  value={userData.data[0].company_name}
                  className={INPUT_CLASS}
                  disabled
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div className="mb-2 sm:mb-6">
                <label htmlFor="email" className={LABEL_CLASS}>
                  Your email
                </label>
                <input
                  value={userData.data[0].email}
                  type="email"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>

              <div className="mb-2 sm:mb-6">
                <label htmlFor="phonenumber" className={LABEL_CLASS}>
                  Phone
                </label>
                <input
                  value={userData.data[0].phonenumber}
                  type="text"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>
              <div className="mb-2 sm:mb-6">
                <label htmlFor="website" className={LABEL_CLASS}>
                  Website
                </label>
                <input
                  value={userData.data[0].website}
                  type="text"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>
              <div className="mb-2 sm:mb-6">
                <label htmlFor="pan_card_no" className={LABEL_CLASS}>
                  PAN
                </label>
                <input
                  value={userData.data[0].pan_card_no}
                  type="text"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>
              <div className="mb-2 sm:mb-6">
                <label htmlFor="gst_no" className={LABEL_CLASS}>
                  GST No
                </label>
                <input
                  value={userData.data[0].gst_no}
                  type="text"
                  className={INPUT_CLASS}
                  disabled
                />
              </div>

              <div className="mb-6">
                <label htmlFor="message" className={LABEL_CLASS}>
                  Address
                </label>
                <textarea
                  rows="4"
                  value={userData.data[0].address}
                  className="block p-2.5 w-full text-sm text-indigo-900 bg-indigo-50 rounded-lg border border-indigo-300 focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-50 disabled:border-gray-300"
                  placeholder="Write your address here..."
                  disabled
                ></textarea>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="flex flex-col gap-x-3">
                <img
                  className="p-1 rounded-lg w-80 h-52 ring-2 ring-gray-300 dark:ring-gray-500"
                  src={userData.data[0].pan_document}
                  alt="PAN"
                />
              </div>
              <div className="flex flex-col gap-3">
                <img
                  className="p-1 rounded-lg w-72 h-80 ring-2 ring-gray-300 dark:ring-gray-500"
                  src={userData.data[0].gst_document}
                  alt="GST"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
